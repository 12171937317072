export default [
  {
    code: 10232030000,
    size: "180x80x225",
    color: "ecobagProperties.color.brown",
    handle: "ecobagProperties.handles.twisted",
    gsm: 90,
    boxSize: 250,
  },
  {
    code: 10432030000,
    size: "240x100x320",
    color: "ecobagProperties.color.brown",
    handle: "ecobagProperties.handles.twisted",
    gsm: 90,
    boxSize: 250,
  },
  {
    code: 10632030000,
    size: "240x100x360",
    color: "ecobagProperties.color.brown",
    handle: "ecobagProperties.handles.twisted",
    gsm: 90,
    boxSize: 250,
  },
  {
    code: 10832030000,
    size: "305x170x340",
    color: "ecobagProperties.color.brown",
    handle: "ecobagProperties.handles.twisted",
    gsm: 90,
    boxSize: 100,
  },
  {
    code: 11432030000,
    size: "500x180x390",
    color: "ecobagProperties.color.brown",
    handle: "ecobagProperties.handles.twisted",
    gsm: 90,
    boxSize: 100,
  },
]
